import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLocationDot, faMobileScreen } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import styles from './Footer.module.scss'
import Logo from '../../assets/images/marli-logo.png'
import FacebookIcon from '../../assets/images/facebook-f.svg'
import LinkedInIcon from '../../assets/images/linkedin-in.svg'

function Footer() {
  const [contactInfo, setContactInfo] = useState({
    phone: '',
    email: '',
    address: ''
  })
  
  const [loading, setLoading] = useState(true)

  // Charger les données de l'API au montage du composant
  useEffect(() => {
    const fetchContactInfo = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/pages/accueil`)
        const data = await response.json()
        setContactInfo(data)
        setLoading(false)
      } catch (error) {
        console.error("Erreur lors du chargement des informations de contact", error)
        setLoading(false)
      }
    }

    fetchContactInfo()
  }, [])

  return (
    <>
      <div className={styles.allContainer}>
        <div className={styles.LogoContainer}>
          <img src={Logo} alt='logo-marli' />
          <p>Passeur d'histoires immobilières</p>
        </div>

        <div className={styles.FooterLinkContainer}>
          <div className={styles.widgetFooter1}>
            <h2>Nos coordonnées</h2>
            <div className={styles.LinkText}>
              <div>
                <FontAwesomeIcon icon={faMobileScreen} />
                <p>
                  <Link to={`tel:${loading ? '' : contactInfo.phone}`}>
                    {loading ? 'Chargement...' : contactInfo.phone}
                  </Link>
                </p>
              </div>
              <div>
                <FontAwesomeIcon icon={faEnvelope} />
                <p>
                  <Link to={`mailto:${loading ? '' : contactInfo.email}`}>
                    {loading ? 'Chargement...' : contactInfo.email}
                  </Link>
                </p>
              </div>
              <div>
                <FontAwesomeIcon icon={faLocationDot} />
                <p>
                  <Link to={`https://maps.app.goo.gl/${loading ? '' : contactInfo.address}`} target='_blank'>
                    {loading ? 'Chargement...' : contactInfo.address}
                  </Link>
                </p>
              </div>
            </div>
          </div>

          <div className={styles.widgetFooter2}>
            <h2>Infos utiles</h2>
            <div className={styles.LinkText}>
              <p>
                <Link to='/honoraires'>Nos honoraires</Link>
              </p>
              <p>
                <Link to='/nous-contacter'>Nous contacter</Link>
              </p>
              <p>
                <Link to='/mentions-legales'>Mentions légales</Link>
              </p>
            </div>
          </div>

          <div className={styles.widgetFooter3}>
            <h2>Nos réseaux</h2>
            <div className={styles.LinkText}>
              <Link target='_blank' to='https://www.facebook.com/marli.immobilier'>
                <img src={FacebookIcon} alt='icon-facebook' />
              </Link>
              <Link target='_blank' to='https://www.linkedin.com/company/marli-immobilier'>
                <img src={LinkedInIcon} alt='icone-linkedin' />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.linkPoweredBy}>
        <div className={styles.imageAuhtorContainer}>
          Crédit photo : Patrick Charrière
        </div>
      </div>
    </>
  )
}

export default Footer
