import React, { useState, useEffect } from 'react'
import styles from './NousContacter.module.scss'
import FormContact from '../../components/FormContact/FormContact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLocationDot, faPhoneFlip } from '@fortawesome/free-solid-svg-icons'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

function NousContacter() {
  const [contactInfo, setContactInfo] = useState({
    phone: '',
    email: '',
    address: ''
  })

  const [loading, setLoading] = useState(true)

  // Charger les données de l'API au montage du composant
  useEffect(() => {
    const fetchContactInfo = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/pages/accueil`)
        const data = await response.json()
        setContactInfo(data)
        setLoading(false)
      } catch (error) {
        console.error("Erreur lors du chargement des informations de contact", error)
        setLoading(false)
      }
    }

    fetchContactInfo()
  }, [])

  return (
    <>
      <Helmet>
        <title>Marli - Contact</title>
        <meta name='robots' content='noindex' />
        <meta name='description' content="Passeur d'histoires immobilières" />
      </Helmet>
      <div className={`fadinAnimation ${styles.allContainer}`}>
        {/* Coordonnées */}
        <div className={styles.coodonneeContainer}>
          <div className={styles.coordonnee}>
            <Link to={`tel:${contactInfo.phone}`}>
              <p className={styles.iconeContainer}>
                <FontAwesomeIcon icon={faPhoneFlip} />
              </p>
            </Link>
            <Link to={`tel:${contactInfo.phone}`}>
              <div>
                <h4>Appelez-nous</h4>
                <p>{loading ? "Chargement..." : contactInfo.phone}</p>
              </div>
            </Link>
          </div>

          <div className={styles.coordonnee}>
            <Link to={`https://maps.app.goo.gl/${contactInfo.address}`} target='_blank'>
              <p className={styles.iconeContainer}>
                <FontAwesomeIcon icon={faLocationDot} />
              </p>
            </Link>
            <Link to={`https://maps.app.goo.gl/${contactInfo.address}`} target='_blank'>
              <div>
                <h4>Notre adresse</h4>
                <p>{loading ? "Chargement..." : contactInfo.address}</p>
              </div>
            </Link>
          </div>

          <div className={styles.coordonnee}>
            <Link to={`mailto:${contactInfo.email}`}>
              <p className={styles.iconeContainer}>
                <FontAwesomeIcon icon={faEnvelope} />
              </p>
            </Link>
            <Link to={`mailto:${contactInfo.email}`}>
              <div>
                <h4>E-mail</h4>
                <p>{loading ? "Chargement..." : contactInfo.email}</p>
              </div>
            </Link>
          </div>
        </div>

        <div className={styles.formContainer}>
          <h3>Dites nous tout</h3>
          <p>Nous vous reviendrons rapidement</p>
          <FormContact />
        </div>
      </div>
    </>
  )
}

export default NousContacter
