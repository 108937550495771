import React, { useEffect, useState } from 'react'
import styles from './A_Propos.module.scss'
import ImageFondatrice from '../../assets/images/fondatrice.jpeg'
import LogoMarli from '../../assets/images/marli-logo.png'
import { Helmet } from 'react-helmet'

function A_Propos() {
  const [pages, setPages] = useState({
    presidente: [""],
    agence: [""]
  });

  // Charger les informations depuis l'API
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/pages/accueil`)
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setPages(data);
        }
      })
      .catch((error) => console.error('Erreur de chargement', error));
  }, []);

 
  return (
    <>
      <Helmet>
        <title>Marli - A propos</title>
        <meta name='robots' content='noindex' />
        <meta name='description' content="Passeur d'histoires immobilières" />
      </Helmet>
      <div className={`fadinAnimation ${styles.allContainer}`}>
        <div className={styles.presentationFondatrice}>
          <div className={styles.imageContainer}>
            <img src={ImageFondatrice} alt='img-fondatrice' />
            <div>
              <h3>Néné</h3>
              <p>Fondatrice</p>
            </div>
          </div>

          <div className={styles.textContent}>
            <p className={styles.borderStyle}></p>
            {pages.presidente.map((para, index) => (
              <p key={index}>{para}</p>
            ))}
            <p className={styles.signatureStyle}>Néné</p>

          </div>
        </div>

        <div className={styles.presentationMarli}>
          <div className={styles.marliLogoContainer}>
            <img src={LogoMarli} alt='img-logo-marli' />
            <p>Passeur d'histoires immobilières</p>
          </div>

          <div className={styles.textContent}>
            <p className={styles.borderStyle}></p>
            {pages.agence.map((para, index) => (
              <p key={index}>{para}</p>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default A_Propos;
